<template>
  <div class="w-full rounded-lg py-4 px-6 shadow-md bg-white">
    <div class="font-bold text-base text-neutral-dark">Remark History</div>
    <div v-if="status !== STATUS_TENANT.Pending" class="py-4 flex gap-2 w-full">
      <div class="w-full">
        <div
          class="
            flex
            items-center
            justify-between
            text-neutral-black text-sm
            pb-1
          "
        >
          <span>{{ remarkDate }}</span>
          <span class="font-bold">{{
            opsUsername ? opsUsername : "officeuser"
          }}</span>
        </div>
        <p
          class="
            bg-status-approved
            py-3
            px-4
            text-sm
            rounded-b-xl rounded-tl-xl
            w-90
          "
          style="word-wrap: break-word; overflow-wrap: break-word"
        >
          {{ remarkContent }}
        </p>
      </div>
      <div>
        <div class="avatar">
          <span class="avatar-text">{{ avatarText }}</span>
        </div>
      </div>
    </div>
    <div v-else class="py-4 w-full text-sm text-neutral-dark">
      {{ remarkContent }}
    </div>
  </div>
</template>

<script setup>
import { formatDateTime } from "@/utils/helpers/convertDateTime";
import { STATUS_TENANT } from "@ems/constants/account_registration_status";
import { computed } from "vue";

const props = defineProps({
  remark: String,
  processedDate: String,
  status: STATUS_TENANT,
  opsUsername: String,
});

const remarkContent = computed(() =>
  props.remark ? props.remark : "No comment yet"
);

const remarkDate = computed(() =>
  props.processedDate ? formatDateTime(props.processedDate) : ""
);

const avatarText = computed(() =>
  props.opsUsername ? props.opsUsername.charAt(0).toUpperCase() : "O"
);
</script>

<style scoped>
.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #9d78cb;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar-text {
  color: white;
  font-weight: bold;
}

:deep(.custom-input .el-input__inner) {
  background-color: #d2ebe2;
  color: #222222;
  border: none;
}
</style>
