import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "rounded-lg py-4 px-6 shadow-md bg-white" }
const _hoisted_2 = { class: "mt-2" }

import { STATUS_TENANT } from "@ems/constants/account_registration_status";
import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'StatusCard',
  props: {
  status: {
    type: Number,
    required: true,
    validator: (value: number) =>
      [
        STATUS_TENANT.Pending,
        STATUS_TENANT.Approved,
        STATUS_TENANT.Rejected,
      ].includes(value),
  },
},
  setup(__props) {

const props = __props;

const statusClass = computed(() => {
  switch (props.status) {
    case STATUS_TENANT.Pending:
      return "bg-status-pending";
    case STATUS_TENANT.Approved:
      return "bg-status-approved";
    case STATUS_TENANT.Rejected:
      return "bg-status-rejected";
    default:
      return "";
  }
});

const statusMessage = computed(() => {
  switch (props.status) {
    case STATUS_TENANT.Pending:
      return "Pending";
    case STATUS_TENANT.Approved:
      return "Approved";
    case STATUS_TENANT.Rejected:
      return "Rejected";
    default:
      return "";
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("p", { class: "font-bold text-base text-neutral-dark" }, "STATUS", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", {
        class: _normalizeClass(["font-bold inline-block py-1 px-4 rounded-md text-neutral-black", statusClass.value])
      }, _toDisplayString(statusMessage.value), 3)
    ])
  ]))
}
}

})